<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Entry -->
        <b-col cols="12" md="2">
          <v-select
            id="period"
            class="select-size-sm"
            label="year"
            v-model="form.year"
            :options="LOV.years"
            :reduce="field => field.year"
            :clearable="false"
          />
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="2">
          <b-button block variant="primary" size="sm" @click="getPeriod">
            {{ $t('globalActions.find') }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <b-card no-body>

      <b-table
        id="refListTable"
        ref="refListTable"
        no-provider-paging
        no-provider-filtering
        :filter-included-fields="columnToBeFilter"
        :fields="tableColumns"
        :items="itemLists"
        :filter="searchQuery"
        responsive
        primary-key="id"
        show-empty
        :busy="isBusy"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <template #table-busy>
          <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
            <div class="text mb-1">
              <b-spinner class="align-middle"/>
            </div>
            <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
          </div>
        </template>

        <!-- Column: code -->
        <template #cell(period)="{ item }">
          <b-link :to="{ name: 'apps-transactions-ch-generate-detail', params: { id: item.id }}">
            <span class="font-weight-bolder d-block text-nowrap">
              {{ item.period }}
            </span>
          </b-link>
        </template>

      </b-table>

    </b-card>

  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  BSpinner,
  BLink
} from 'bootstrap-vue'

import { getYear } from 'date-fns'
import { ref, onMounted } from '@vue/composition-api'
import { formatCurrency } from '@/utils/formatter'
import vSelect from 'vue-select'
import useListTable from '@/comp-functions/useListTable'
import useFormResource from '@/comp-functions/useFormResource'

// to access i18n
import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BButton,
    BLink,
    vSelect
  },
  setup () {
    const form = ref({
      year: getYear(new Date())
    })

    const LOV = ref({
      years: []
    })

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const { get } = useFormResource({ url: false })

    const { $t } = useLocalization()

    // Table Handlers
    const tableColumns = [
      { key: 'period', label: $t('apps.transactionsClosing.singular.period') },
      { key: 'status', label: $t('globalSingular.status')  },
      { key: 'is_generated', label: 'Is Generate' },
      { key: 'closing_date', label: 'Generate Date' },
      { key: 'closing_by', label: 'Generate By' }
    ]

    // Table Handlers
    const columnToBeFilter = ['period']

    const getYears = async () => {
      LOV.value.years = await get({ url: 'value/year-book-period'})
    }

    const getPeriod = async () => {
      const params = [`year=${form.value.year}`]

      const url = `transaction/chbacc/ch-generate?${ params.join('&') }`
      await fetchLists(url)
    }

    onMounted(async () => {
      await getYears()
      await getPeriod()

    //   setInterval(async function () {
    //     await getPeriod()
    // }, 5000);

    })

    return {
      form,
      LOV,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      formatCurrency,
      tableColumns,
      columnToBeFilter,
      getPeriod
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
